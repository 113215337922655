<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Utilities Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/utilities-pages.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Timer:</u></p>
      
      <ul> 
        <li>This page allows you to keep track of hours worked, breaks, comments and photos your employees upload on their mobile app.</li>
    <li>You can also monitor past timers and active timers from the backend.</li>
    <li>Admins are in control of what information their employees are required to fill while they use the timer feature.</li>
    <br/>
      </ul>
      <div class="yellow-banner">
      Note: You will have to tick the “Can Timer” checkbox <img :src="`${publicPath}assets/how-to/can-timer.webp`" alt="" style="width:10%" /> on their profile to make the option to send dockets available on their mobile app.
    </div><br/>
    <p><u>Prefiller Manager:</u></p>
      <ul> 
        <li>Pre-filler allows you to add a list of tasks/job numbers/Rego number or any type of fixed information that gets used on a regular basis.</li>
    <li>Instead of typing repetitive information on every docket, a drop down field is created on mobile apps to enable quicker, faster and accurate data capture.</li>
    <li>Essentially, the pre-filler is made to make the docket creation process easier for the mobile app users. </li>
    </ul><br/>
    <img :src="`${publicPath}assets/how-to/created-prefiller.webp`" alt="" style="width:100%" /><p>Custom prefillers created from "Utilities > Prefiller Manager" Menu</p>
    <img :src="`${publicPath}assets/how-to/machine-prefiller.webp`" alt="" style="width:100%" /><p>Elements from docket template to link a prefiller template</p>
    <img :src="`${publicPath}assets/how-to/load-prefiller.webp`" alt="" style="width:50%" /><p>Option to load Prefiller</p>
    <img :src="`${publicPath}assets/how-to/prefiller-on-mobile-app.webp`" alt="" style="width:50%" /><p>Prefiller options added for mobile users by Admin</p>
    <div class="yellow-banner">
      Note: There are two default lists you can add on your dockets. "Employee List and Client List", you will have to create other pre-filler templates/list as required and link it inside a template with your docket fields.
    </div>
    <p><u>Docket Manager</u></p>
      <ul> 
        <li>Document manager page allows you to add PDF files to a docket template by default.</li>
    <li>Your employees can also click it while creating a docket to view it if need be.</li>
    <li>You can use it to attach your SWMS, risk assessments, safety guidelines and much more.</li>    
    </ul><br/>
    <img :src="`${publicPath}assets/how-to/document-manager.webp`" alt="" style="width:100%" />
    <img :src="`${publicPath}assets/how-to/add-documents.webp`" alt="" style="width:100%" />
    <p>Adding Documents in docket template with "Document" element</p>
    <div class="yellow-banner">
        Note: You can create a list of documents/pdfs from "Utilities > Document Manager" page, attach a “Document” field in your docket template and choose it from the drop down list to make your dockets complaint as required.
    </div>
    <p><u>Timesheet</u></p>
      <ul> 
        <li>This page lets you connect your timesheet and invoices to Xero accounting platform.</li>
    <li>To connect your timesheet docket template, you will have to enable “Xero Timesheet” field while creating a new template. </li>   
    </ul><br/>
    <img :src="`${publicPath}assets/how-to/xero-timesheet.webp`" alt="" style="width:100%" />
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  