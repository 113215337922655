<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Employee Management Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/employee-management-menu.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Manage Employees:</u></p>
      <ul>
        
        <li>Employee management page lets you manage your employees.</li>
    <li>You can see their full name, email address, if they are admin or currently employed or not and if they are active or inactive.</li>
    <li>Click here to learn "<a href="https://recordtime.com.au/help/how-does-manage-employees-page-work">How does Manage Employees page work?</a>".</li><br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/employee-details.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Manage Plant:</u></p>
      <ul>
        
        <li>From this page you can add details of your plant/equipment/vehicle details.</li>
    <li>You can also put an alert/reminder. For example: <b>X days</b> before the expiry of registration of your plant.</li>
    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/all-plants.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Message/Reminders:</u></p>
      <ul>
        
        <li>From this page you can message/send alerts & reminders to your employee individually or by creating a group/project.</li>
    <li>Once you send a message from this page, it will pop up on their record time mobile application. For this to work, they have to be signed in on their mobile app.</li>
    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/message-reminder.webp`" alt="" style="width:50%" /><br/><br/><br/><p>Message/Reminder from backend</p>
    <img :src="`${publicPath}assets/how-to/reminder-on-the-phone.webp`" alt="" style="width:50%" /><br/><br/><br/><p>Notification on app</p>
      <p><u>Employee Leave and Plant Availability:</u></p>
      <ul>
        
        <li>From this page, you are able to add & track employees that are on leave and plant/equipment that are on leave for servicing. This will ensure you know what resources you have available at your disposal. </li>
    <li>For this to work, you need to create your plants from “Manage Plant” and employees from “Manage Employees” page.</li>
    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/plant-management.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>View Plant Usage:</u></p>
      <ul>
        
        <li>In this screen, you can see how busy a particular plant item is and for which dates it is scheduled.</li>
    <li>It is a calendar that shows the various jobs the plan item is assigned.</li>
    <br/>
  
    </ul>
    






          <!-- <li>How does Manage Employees page work?</a></li>
          <li><a @click="$router.push({ name: 'helpDetail', params: { id: 'how-does-manage-employees-page-work'} })">Getting started with Record Time</a></li> -->
          <img :src="`${publicPath}assets/how-to/plant-usuage.webp`" alt="" style="width:100%" /><br/><br/><br/>
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  