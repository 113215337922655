<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Profile Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/profile-page.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Mr Subscription:</u></p>
            <ul> 
        <li>This page allows you to view your existing plan information and lets you upgrade or downgrade your plan. </li>
        <br/>
      </ul>
      <p><u>Profile:</u></p>
            <ul> 
        <li>To come to this section, you can click your name from the top right corner of the page.</li>
        <li>Once you click your name, you come to your profile page where you can update your company and contact details.</li>
        <br/>
      </ul>
      <p><u>Invoice Setting:</u></p>
            <ul> 
        <li>If this section is completed, your banking information is automatically listed on the bottom of your invoices.</li>
        <li>You can also amend the “instruction” or “additional information” text box as required. This will ensure your customers know where to pay your invoice and your company’s terms for payment.</li>
        <li>Once your banking information is entered, please click the save button to save the information.</li>
        <li>Any invoices sent from this point onwards to your clients will automatically display your banking details and additional information.</li>
        <br/>
        <br/>
      </ul>
      <img :src="`${publicPath}assets/how-to/setting-invoice.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <img :src="`${publicPath}assets/how-to/banking-invoice.webp`" alt="" style="width:100%" /><br/><br/>  
      <p><u>Docket Settings:</u></p>
            <ul> 
        <li>You can create terms and conditions template from this page.</li>
        <li>Simply click the “Add New” button and a pop up window will appear. You just have to add a title and your terms and conditions to get started.</li>
        <br/>
        <br/>
      </ul>
      <img :src="`${publicPath}assets/how-to/docket-setting-tnc.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <img :src="`${publicPath}assets/how-to/adding-tnc-from-docket-template.webp`" alt="" style="width:100%" /><br/>
      <p><u>Change Password</u></p>
      <ul> 
        <li>This page allows you to change/update your password or you can update it from the employee management page. </li>
    </ul><br/>  
    <p><u>Update Credit Card:</u></p>
      <ul> 
        <li>This page lets you add, update or delete your credit card details.</li>
    <li>Please update your credit card details to avoid interruptions after your trial ends.</li>
   
    </ul><br/>
    <p><u>Billing History</u></p>
      <ul> 
        <li>This page shows all the invoices you have paid for the use of Record Time software.</li>
    <li>You can view the date of payment, total amount paid and even download invoices from this section. </li>
   
    </ul><br/>
    <p><u>Xero Setting:</u></p>
      <ul> 
        <li>This page lets you link your Xero software with Record Time to enable syncing invoices and timesheet. </li>
   
    </ul><br/>
    <p><u>Date Settings:</u></p>
      <ul> 
        <li>By default, the users are able to select any date range.</li>
        <li>With the restricted dates, you can control how far back or in future they are able to pick the dates.
</li>
   
    </ul>








      
   
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  