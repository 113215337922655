<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Invoice Manager Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/invoice-manager-record-time.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Invoices</u></p>
      <p>Invoice page works similar to dockets page. From this page you are able to view all Sent, Received and Emailed invoices.</p>
      <ul>
        
        <li>From this section you can export csv/excel files or pdf’s.</li>
    <li>Move invoices into folders </li>
    <li>You can create folders to make it project specific and set it up in a hierarchy similar to your folders on the computer.</li>
    <li>You are also able to send invoices directly from the backend, filter invoices, view them and much more. </li>
    <br/>
  
    </ul>
    <p><u>Invoices Templates:</u></p>
      <ul> 
        <li>Similar to dockets templates page, from this page you are able to create new invoice templates, edit existing templates, delete and assign templates to a folder.</li>
    <li>Assigning invoice to a specific folder will automatically be placed into the selected folder for easier file keeping.</li>
    </ul><br/>
    <p><u>Assign Invoice Templates:</u></p>
      <ul> 
        <li>This page lets you assign invoice Templates to Employees through this menu or you can assign them directly before publishing your templates.</li>
    <li>You can nominate who can send invoices to your clients directly.</li>
    </ul>
    <div class="yellow-banner">
      Note: You will have to tick the “Can Invoice” checkbox <img :src="`${publicPath}assets/how-to/can-invoice.webp`" alt="" style="width:10%" /> on their profile to make the option to send dockets available on their mobile app.
    </div>
      <p><u>Invoice Label</u></p>
      <ul>
        
        <li>Invoice label concept works the same as docket label. However, the labels created on this page are only accessible on invoices.</li>
    <li>Invoice label page lets you create Invoice Labels to mark your invoices. Invoice labels are like tags or post-it notes in the real world, they help you mark off or highlight an invoice to keep track of its status.</li>
    <br/>
  
    </ul>
    <p><u>Invoice Setting</u></p>
      <ul>
        
        <li>If this section is completed, your banking information is automatically listed on the bottom of your invoices.</li>
    <li>You can also amend the “instruction” or “additional information” text box as required. This will ensure your customers know where to pay your invoice and your company’s terms for payment.</li>
    <li>Once your banking information is entered, please click the save button to save the information.</li>
    <li>Any invoices sent from this point onwards to your clients will automatically display your banking details and additional information.
</li>
    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/invoice-setting-record-time.webp`" alt="" style="width:100%" /><br/><br/>      
    <img :src="`${publicPath}assets/how-to/invoice-banking-record-time.webp`" alt="" style="width:100%" /><br/><br/> 
    <center><p>Banking on Invoice</p>  </center>       
   
              
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  