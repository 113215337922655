<template>
  <div style="margin-top: 40px;">
    <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
    <h1>Scheduler Menu</h1><br/><br/>
    <img :src="`${publicPath}assets/how-to/Scheduler.webp`" alt="" style="width:100%" /><br/><br/><br/>
    
    <ul>
      
      <li>The scheduler allows you to allocate jobs and plant to your staff. Here you can add job details, comments, location of the job and assign forms/dockets that need to be filled as part of the job. </li>
  <li>You can simply click a day from the calendar to add tasks as required.</li><br/>

  </ul>
        <!-- <li>How does Manage Employees page work?</a></li>
        <li><a @click="$router.push({ name: 'helpDetail', params: { id: 'how-does-manage-employees-page-work'} })">Getting started with Record Time</a></li> -->
        <img :src="`${publicPath}assets/how-to/Scheduler-calendar.webp`" alt="" style="width:100%" /><br/><br/><br/>
</div>
</template>
<style scoped lang="scss">
  .yellow-banner{
    background-color: #ffe652;
    padding: 10px;
    border-color: #deca4f;
    color: #615959;
    border-left-width: 5px;
    border-left-style: solid;
    margin: 15px 15px 15px 0px;
  }
</style>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    changeParam(param){
      window.location = window.location.origin + "/"+ param
    }
  }
};
</script>
