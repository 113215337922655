<template>
  <div style="margin-top: 40px;">
    <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
    <h1>How does Manage Employees page work?</h1><br/><br/>
    <img :src="`${publicPath}assets/how-to/manage-employee-page-backend.webp`" alt="" style="width:100%" /><br/><br/><br/>
    <img :src="`${publicPath}assets/how-to/add-employee.webp`" alt="" style="width:100%" /><br/><br/><br/>
    <ul>
      <li>Go to "Employee & Plant Management > Manage Employees" page in the backend and click the "Add New" button from the right side of the page to add an employee.</li>
      <li>Please enter your employees details in the field and ensure their email address is valid. You can create a password for them and once their profile has been saved, an email highlighting the instruction, username, password and a link to download the mobile app will be sent to get them started.</li>
    </ul><br/><br/>
    <img :src="`${publicPath}assets/how-to/Email-with-Pass-for-employees.png`" alt="" style="width:100%" /><br/><br/>
    <ul>
      <li>You can update your employees profile at any time to make them an admin, to make them active or inactive depending on their employment status and much more.</li>
      <li>You can hover over the help icons to see what you and your employees can achieve by ticking or unticking the given checkboxes.</li>
      <li>You can control what they see on their mobile app to ensure the ease of use. Being an admin/super admin, you can grant them the ability to send dockets or invoices and start a timer or send email dockets to clients. If they are a part of your administration team or someone who needs an access to backend system, it is as easy as ticking the checkbox to make them an admin and saving the details for that particular employee. </li>
    </ul><br/>
    <div class="yellow-banner">
      Note: If someone no longer works for your company or is quitting their job, you are able to make them inactive by unticking “Currently Employed?” checkbox on their profile and saving it. It is as easy as ticking and unticking to grant them the access they need.
    </div><br/><br/>
    <img :src="`${publicPath}assets/how-to/User-permissions-checkbox.png`" alt="" style="width:100%" /><br/><br/>
  </div>
        <!-- <li>How does Manage Employees page work?</a></li>
        <li><a @click="$router.push({ name: 'helpDetail', params: { id: 'how-does-manage-employees-page-work'} })">Getting started with Record Time</a></li> -->
</template>
<style scoped lang="scss">
  .yellow-banner{
    background-color: #ffe652;
    padding: 10px;
    border-color: #deca4f;
    color: #615959;
    border-left-width: 5px;
    border-left-style: solid;
    margin: 15px 15px 15px 0px;
  }
</style>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    changeParam(param){
      window.location = window.location.origin + "/"+ param
    }
  }
};
</script>
