<template>
  <div>
    <landing-banner>
      <template v-slot:title>
        Record TIME Frequently Asked <span>Questions</span>
      </template>

      <template v-slot:subTitle>
        This page provides help with the most common questions about Record TIME.
        Below you will find an answers to the questions.
      </template>

      <template v-slot:image>
        <img :src="require('../assets/About/banner.png')" alt="Home banner image">
      </template>

      <!-- <template v-slot:main>
        <secondary-btn-row text1="General" text2="Docket"/>
      </template> -->
    </landing-banner>

    <v-container class="accordions-wrapper">
      <v-row no-gutters>
        <v-col md="10" lg="10" xl="8">
          <v-expansion-panels accordion flat>
            <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
              <v-expansion-panel-header color="#f8f9fb" :expand-icon="mdiPlus">
                {{ faq.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="" v-html="faq.desc"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="faq-notice-container">
      <div class="left-imgs">
        <img :src="require('../assets/Faq/5.jpg')">
        <img :src="require('../assets/Faq/6.jpg')">
      </div>

      <div class="faq-content">
        <p class="faq-content__title">Still need help? Send us an email!</p>
        <p class="faq-content__desc">
          For more Questions, how-tos and other documentation please
          <a href="https://help.recordtime.com.au/hc/en-us/categories/360000153335-General"> click here </a>
          <a href = "mailto:info@recordtime.com.au">info@recordtime.com.au</a> or
          <a href="tel:0421955630">0421 955 630</a>
        </p>
      </div>

      <div class="right-imgs">
        <img :src="require('../assets/Faq/7.jpg')">
        <img :src="require('../assets/Faq/8.jpg')">
      </div>
    </v-container>
  </div>
</template>

<style lang="scss">
.accordions-wrapper {
  .v-expansion-panels {
    > .v-expansion-panel {
      margin-bottom: 24px;

      > button.v-expansion-panel-header {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        color: $black-pearl;

        div.v-expansion-panel-header__icon {
          span.v-icon {
            color: $faux-dark-turquoise;
          }
        }
      }

      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding-top: 30px;
          opacity: 0.6;
          font-size: 18px;
          line-height: 1.78;
          letter-spacing: normal;
          color: $black-pearl;
        }
      }
    }
  }
}

.faq-notice-container {
  margin-top: 80px;
  @media only screen and (max-width: 600px) {
    margin-top: 24px;
    flex-direction: column;
  }

  @media only screen and (min-width: 600px) and (max-width: 960px) {
    margin-top: 40px;
  }

  display: flex;
  justify-content: space-between;

  > div {
    height: 144px;

    @media only screen and (min-width: 600px) and (max-width: 960px) {
      height: 100px;
    }
  }

  .faq-content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      max-width: unset;
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      text-align: center;
      color: $black-pearl;
      margin-bottom: 12px;
    }

    &__desc {
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
      color: $black-pearl;

      a {
        color: $faux-dark-turquoise;
        text-decoration: underline;
      }
    }
  }

  .left-imgs, .right-imgs {
    img {
      height: 100%;
      object-fit: cover;
      object-position: top;

      @media only screen and (max-width: 600px) {
        width: 50%;
      }
    }
  }
  .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
   

  .left-imgs {
    @media only screen and (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}
</style>

<script>
// @ is an alias to /src
import LandingBanner from '@/components/LandingBanner.vue';
import SecondaryBtnRow from '@/components/SecondaryBtnRow2.vue';
import {mdiPlus} from '@mdi/js';

export default {
  metaInfo: {
    title: 'Faq',
    titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: ''}
    ]
  },
  components: {
    LandingBanner
  },
  data() {
    return {
      faqs: [
        {
          title: "Can I cancel my subscription at any time?",
          desc: "Yes. There are no lock in contracts. Payments are month to month and can be cancelled at any time. You can also upgrade/downgrade your account at any time."
        },
        {
          title: "Are there set up costs?",
          desc: "No set up costs as it stands. You can follow our help / how tos  and set it up yourself. If you do need assistance, we can organise someone and let you know the costs involved. Please get in touch if this is the case."
        },
        // {
        //   title: "How can I change my password and my employees from the backend?",
        //   desc: "Once you have logged in to the back end with your username and " +
        //       "password you can then go to the Employees Tab and select Update from the " +
        //       "far right hand side. This then allows you to make changes to Employees Passwords " +
        //       "and other associated information."
        // },
        {
          title: "Do you offer support and what hours?",
          desc: "Yes. All customers get basic support Monday-Friday during business hours. If you would like to purchase additional support, you may do so by visiting your subscription page."
        },
        {
          title: "Where is Record TIME based?",
          desc: "Our head office is in Canberra. However, we support and have customers all over Australia."
        },
        {
          title: "Do you help us set up our account if needed?",
          desc: "Yes. You can engage one of our onboarding specialists to fast-track your setup. Please get in touch for rates and other details."
        },
        {
          title: "If I publish a docket template I have created to Docket Bank/Template Library, will I have to delete my pre-fillers?",
          desc: "No, pre-fillers will not stay with templates as they are specific to your personal account."
        },
        {
          title: "How do I import a docket template?",
          desc: "Docket Book Manager > Docket Templates > click “Import/Export” > Make sure “Import” is selected > title your docket > “select file to import” from file location > “Import Docket Template”."
        },
        {
          title: "Can I send a docket template to another Record TIME customer (outside of employees)?",
          desc: "Yes, you can email the file to another Record TIME user. Docket Book Manager > Docket Templates > click “Import/Export” > Make sure “Export” is selected > select desired docket template > “Export Docket Template”"
        },
        {
          title: "How do I clone a docket?",
          desc: "Docket Book Manager > gear icon (on right side of the preferred docket) > clone"
        },
        {
          title: "Can I edit a docket that my employees are already using?",
          desc: "Yes, you can move elements, change titles, etc. however, you can’t delete any elements. To make major changes, clone (Click the clone button on the desired template and assign it to your employees) the docket."
        },
        {
          title: "How do you unassign a docket?",
          desc: "Docket Book Manager > Assign Docket Template > click the red trash can to delete/unassign the docket"
        },
        {
          title: "How do you assign a docket template in Record TIME?",
          desc: "Docket Book Manager > Assign Docket Template > Click Assign New."
        },
        {
          title: "My employee cannot see any docket templates, what do I do?",
          desc: "Please make sure you have assigned the employee the docket templates."
        },
        {
          title: "How do I add my logo to a docket?",
          desc: "In “My Account” you are able to upload an image of your company logo and it will automatically be added to each docket that is sent."
        },
        {
          title: "Can I draft a docket?",
          desc: `Yes. You can also use a similar approach to use your mobile app offline/when there is no network.<br/><br/>
          <p><b>How to create a draft?</b></p>
          <ul>
            <li>Create a docket on your mobile application by selecting a "docket template" that has been assigned to you. </li>  
            <li>Choose a recipient from the list. You can choose a recipient/s from "Record Time Users" tab or from "Custom Email Clients" and click "Next".</li>
            <li>Complete the necessary information on the docket, go to the bottom of the page and click "Save Docket".</li>
            <li>It will ask you to name your docket. Please name it properly to make it easier to remember when accessing it in the future.</li>
          </ul><br/>
          <p><b>How to retrieve/amend a draft and send it?</b></p>
          <ul>
            <li>Click the "Three lined icon/Menu" from the left side of your mobile app.</li>  
            <li>On the list, select "Saved Dockets" to retrieve your dockets draft.</li>
            <li>You will be able to see all draft dockets if you have more than one saved. That is why it is important to name them correctly to make it easier to access it.</li>
            <li>Click the "docket draft" you are after and amend/update accordingly. </li>
            <li>You can either save the docket again with your amended information or you can send it to your client by clicking "Send Now".</li> 
            <li>Once the docket has been sent, you will receive a pop-up window asking "Do you want to delete the saved docket?". If you no longer need it, then you can click "Yes" to delete it from the "Saved Dockets" list.</li> 
          </ul>
        `
  
        },
       
        {
          title: "Can I send dockets and invoices to email?",
          desc: "Yes, dockets can be sent to both Record Time users and non Record Time users via email from your mobile application. You can also export/forward a docket as pdf on the mobile app or in the backend and send it separately. "
        },
        {
          title: "Can I send a docket from the backend?",
          desc: "Currently this feature is not available. We are working daily on new features and improvements to make Record Time easier to use for our customers. It is a part of many improvements that will be coming in the future. Stay tuned!"
        },
        {
          title: "Will all my employees see all the templates?",
          desc: `No. After creating a template, you have to assign which employees can access the template. An admin has an ability to assign or remove templates from their employees account.<br/><br/>
          
          <ul>
            <li>Login to your backend and click "Docket Book Manager >> Assign Dockets Template" from the menu.</li>  
            <li>You will see the list of employees that are assigned to templates.</li>
            <li>You can remove them from a particular template by clicking a "delete icon" or you can assign them to new templates by clicking "Assign".</li>
            <li>Ensure you have created your templates before assigning employees and you can assign multiple employees at once to a template via the pop up window after clicking "Assign" button.</li>
          </ul>
         
        `
        },
        {
          title: "How many docket templates can I have?",
          desc: "There is no limit to the number of templates you can create and use. You can tailor as many templates as you need for your business or for your upcoming projects."
        },
        {
          title: "Can I use my mobile app offline or on non mobile network connected areas?",
          desc: `The offline version of the app can be used to create dockets. This will only work if all of your information has been loaded at least once prior to being offline. In addition, the template would have to be loaded/clicked at least once prior to being offline.<br/><br/>
          
          <ul>
            <li>Once a docket has been created on offline mode, you will have to save the docket by clicking "Save Docket" and give it a name to identify it later with ease. </li>  
            <li>After you get to an area with mobile network/wifi, click the menu button on the top left corner of the app and view "Saved Dockets". Click the docket from the list and simply click "Send" or amend the docket before sending.</li>
            <li>After the docket has been sent, it will ask you if you want to keep the draft of the docket. You can now delete the draft if you no longer need it.</li>
         
          </ul>
         
        `
        },
        {
          title: "Can multiple people approve a docket?",
          desc: "Yes, you can select who can approve the docket before you send it."
        },
        {
          title: "Can I send a docket to multiple people?",
          desc: `Yes. You can select multiple recipients while sending a docket and you can also nominate who can approve the docket if you are sending it to more than one person.<br/><br/>
          
         
          <p><b>Please note:</b> You cannot send dockets to multiple recipients if one recipient is "Email Clients/Recipient" and the other is "Record Time User/Your clients connected on Record Time". </p><br/>
          You can also use the "Share PDF" option available on the app. Click the docket you want to share on the app and on the top right hand corner you will see a share icon <img :src="require('../assets/Faq/share-icon.webp')">. Once you click it, you will get the following option to share: <br/>
          <img :src="require('../assets/Faq/share-pdf.webp')">
         
        `
        },
        {
          title: "My Employee has left the company, what do I do?",
          desc: 'You will have to turn off the "Currently Employed" checkbox from their profile. In order to do so, go to your backend: Employee Management > Manage Employees > “Update” preferred employee >  Uncheck “Currently Employed?” > Submit'
        },
        {
          title: "How do I upgrade my account?",
          desc: "Click on your name on the top right corner. Then click My Subscription > Upgrade"
        },
        {
          title: "Can I cancel a subscription at any time?",
          desc: "Yes, there is no lock-in contract. With our month to month based payment plan, you are able to end your subscription at any time. You will still have access to the program for the remainder of the month that you have paid for."
        },
        {
          title: "How do I rename a folder?",
          desc: "Docket Book Manager > Dockets > on the side bar where folders are located > hover your mouse on the right side of the desired folder (three dots will appear) > click the dots and select edit > update the title and save changes"
        },
        {
          title: "Can I add links to Record TIME?",
          desc: "Currently this is not possible, however, you can attach pdf documents to your docket"
        },
        {
          title: "What happens if I don’t have internet/service where I work? Can I still use Record Time?",
          desc: "Yes. Record TIME works without an internet connection, provided all the data has been downloaded once before."
        },
        {
          title: "Can more than one person be logged into the same account?",
          desc: "Yes, but only on the backend (which is admin access only), the app only allows one user per account logged in at a time."
        },
        {
          title: "What are the character limits on the short text and long text elements?",
          desc: "Character wise, there are no limits. However, the short text is limited to single line entries, whereas, long text allows multiple lines."
        },
        {
          title: "What are Docket/Invoice labels?",
          desc: "Docket/Invoice labels are like tags or Post-it notes. They help you mark off or highlight a docket/invoice to keep track of its status. You can colour code it, use an icon or name it as required. Example: processed or entered in MYOB"
        },
        {
          title: 'What does "Xero Connection Expired" mean?',
          desc: 
          //<img :src="require('../assets/Faq/xero-expired-message.webp')"> 
          'When you are syncing your data to Xero, you only have 30 minutes to do so. If you require more time, please reconnect to Xero by going to Utilities -> Timesheet '
        },
        {
          title: "Can I connect with other Record Time users?",
          desc: 'Yes, go to ‘Clients’ tab and click ‘Find Client’ button. If they are registered in Record Time, they will appear. Send them a client request and once they accept they will be visible in the app.<br/>Find detailed instruction on: How does "Client Management Page" work?'
        },
        {
          title: "Can I Delete Dockets?",
          desc: //<img :src="require('../assets/Faq/trash-icon.webp')">
          "Yes, you can. Delete them individually in the backend by selecting them and pressing the red trash icon. You will have 30 days to recover them, after which they will be permanently deleted."
        },
        {
          title: "Having trouble signing up / activating your account?",
          desc: "Please contact us for any issues. If you did not receive the activation email, please double check your junk/spam folder. Otherwise contact our technical support team at support@recordtime.com.au or 0421 955 630"
        },
        {
          title: "Can I export to PDF?",
          desc: `Yes, you can export invoices and dockets to PDF. This can be done individually or by selecting a list of them in the backend.<br/><br/>
          
         
          <p>You can also use the "Share PDF" option available on the app. Click the docket you want to share on the app and on the top right hand corner you will see a share icon <img :src="('../assets/Faq/share-icon.webp')">. Once you click it, you will get the following option to share:<br/>
 
            <img :src="require('../assets/Faq/share-pdf.webp')">
   
        `
        },
        {
          title: "Can I export to MYOB/Xero?",
          desc: "You can currently export invoices and timesheets to Xero. Exporting to MYOB is under development. However, all dockets and invoices can be exported as a CSV file. This export can be customised using our export mapping feature."
        },
        {
          title: "Can I export to Excel?",
          desc: "Yes, all dockets and invoices can be exported as a CSV file."
        },
        {
          title: "Can I control which parts my employee sees?",
          desc: `Yes. You can select whether an employee can create dockets, invoices or use the timer feature and much more. Go to "Employee Management >> Manage Employees" to update their profile. You can also only assign them with docket/invoice templates they need to see/use.

                `
        },
        {
          title: "Who counts as a user?",
          desc: "Anyone who requires unique access to the system with a username and password. These include the super admin, admin and non admin users."
        },
        {
          title: "Am I paying for inactive employees?",
          desc: "No. Inactive employees can be replaced with new active employees or your plan can be reduced to fit the number of active users."
        },
        {
          title: "Can I remove an employee or temporarily suspend their account?",
          desc: "You cannot delete an employee, because you may need to access dockets they have created. However, you can deactivate or flag them as currently not employed."
        },
        {
          title: "Can I control my employees login access?",
          desc: 'Yes. Employee access can be controlled via the "Employee Management >> Manage Employees" Page. You can change their password or simply untick "Currently Employed" checkbox and update the profile.'
        },
        {
          title: "Can I have multiple admins?",
          desc: 'Yes. However, there will always be a Super Admin, who is the admin of all users and other admins. Other admins will have access to the same features as the Super Admin, without affecting the super admin role. Super admin is the person/email who signed up for the account.'
        },
        {
          title: "Can I upgrade, downgrade or cancel my account?",
          desc: `Yes, you can make all these changes at any time by logging into your backend.<br/><br/>
          <b>Upgrade or downgrade</b>
            <p>Click your profile image on the top right hand corner in the backend and click "My Subscription" from the menu. Click "Upgrade" button and from the list of plans, you can upgrade or downgrade as you need.</p>       
            <div class="yellow-banner">
        Note:  The payment will be based on the pro-rata charges.<br/><br/>
      </div>
            <p><b>Cancellation</b></p>
          <p>You can cancel your subscription at any time you like. There is no lock in Contract. Just click your profile image on the top right hand corner in the backend and click "Update Credit Card" details from the menu. You will be able to remove your card details by clicking "Remove Card Details".</p>
  
        `
        },
        {
          title: "Can I send a docket from an Iphone to an Android Phone?",
          desc: "Yes Record TIME will allow you to send dockets between different devices and you can also directly send dockets to email addresses."
        },
        {
          title: "What hours does Record Time offer support?",
          desc: "Support is available by telephone during the hours of 9:00 am – 5:00 pm Monday to Friday, and by email at any time. Support by other means or at other times (including training) is not included in the Monthly Subscription Fee. However, may be arranged by further negotiation."
        },
        {
          title: "How can I change my password and my employees from the backend?",
          desc: 'Once you have logged in to the backend with your (admin) username and password you can then go to the "Employee Management >> Manage Employees" page to see the list of your employees including yourself. You can click the "Update" button to update passwords for yourself or for your employees.'
        },
        {
          title: "How many employees can I have on the system?",
          desc: "The number of employees is not limited. Please view our plan details for further details or contact us for a customised account."
        },
        {
          title: "Can I cancel my subscription at any time?",
          desc: 'Yes you can cancel your subscription at any time you like. There is no lock in Contract. Click your profile image on the top right hand corner in the backend and click "Update Credit Card" details from the menu. You will be able to remove your card details by clicking "Remove Card Details".'
        },
             
      ],
      mdiPlus: mdiPlus
    }
  }
}

</script>
