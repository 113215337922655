<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>How to share your templates with other users/Record TIME community?</h1><br/>
<p>"Template Bank” is a community uploaded library of useful docket templates. If you have designed a template that you think other users can benefit from, please add them to the template bank. Please note this is visible by all Record Time users, not just your employees. Therefore, we ask you to not submit any sensitive information.</p>
<p>To share a template, go to "Docket Book Manager >> Docket Templates " and click the red settings icon on your template to publish it. Once published, your template will be displayed on "Docket Bank" for everyone to install.</p>
<img :src="`${publicPath}assets/how-to/Publishing-a-template.webp`" alt="" style="width:100%" />  
     
        </div>
  </template>
  <style scoped lang="scss">
   
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  