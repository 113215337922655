<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Client Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/client-page-menu.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Clients</u></p>
      <ul>
        
        <li>Clients tab/page lets you add a client from the list of already signed up and happy clients to send dockets straight to their mobile apps.</li>
    <li>This allows for quick in-app notifications and dockets approvals. </li>
    <li>Please get in touch with us to add a company that is already using the Record Time app.</li><br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/add-clients.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Custom Clients:</u></p>
      <ul>
        
        <li>If the clients you are working with are not already using Record Time, you have the option to add them as “Custom Clients” by adding their email address and company details on the “Custom Clients” page.</li>
    <li>The details you enter for your clients such as their Email, full name, company name and address will be used to send dockets and invoices via email.</li>
    <li>Once added, you are able to edit or delete them from the custom clients list as required.</li>
    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/email-clients-backend.webp`" alt="" style="width:100%" /><br/><br/><br/>
      
<center>

     <u><p>Adding Custom Email Client on Backend</p>   </u>  </center>
          
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  