var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"40px"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'help' })}}},[_vm._v("◀ How To's")]),_c('h1',[_vm._v("Employee Management Menu")]),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/employee-management-menu.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(0),_vm._m(1),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/employee-details.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(2),_vm._m(3),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/all-plants.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(4),_vm._m(5),_c('img',{staticStyle:{"width":"50%"},attrs:{"src":`${_vm.publicPath}assets/how-to/message-reminder.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("Message/Reminder from backend")]),_c('img',{staticStyle:{"width":"50%"},attrs:{"src":`${_vm.publicPath}assets/how-to/reminder-on-the-phone.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("Notification on app")]),_vm._m(6),_vm._m(7),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/plant-management.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(8),_vm._m(9),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/plant-usuage.webp`,"alt":""}}),_c('br'),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Manage Employees:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Employee management page lets you manage your employees.")]),_c('li',[_vm._v("You can see their full name, email address, if they are admin or currently employed or not and if they are active or inactive.")]),_c('li',[_vm._v("Click here to learn \""),_c('a',{attrs:{"href":"https://recordtime.com.au/help/how-does-manage-employees-page-work"}},[_vm._v("How does Manage Employees page work?")]),_vm._v("\".")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Manage Plant:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("From this page you can add details of your plant/equipment/vehicle details.")]),_c('li',[_vm._v("You can also put an alert/reminder. For example: "),_c('b',[_vm._v("X days")]),_vm._v(" before the expiry of registration of your plant.")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Message/Reminders:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("From this page you can message/send alerts & reminders to your employee individually or by creating a group/project.")]),_c('li',[_vm._v("Once you send a message from this page, it will pop up on their record time mobile application. For this to work, they have to be signed in on their mobile app.")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Employee Leave and Plant Availability:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("From this page, you are able to add & track employees that are on leave and plant/equipment that are on leave for servicing. This will ensure you know what resources you have available at your disposal. ")]),_c('li',[_vm._v("For this to work, you need to create your plants from “Manage Plant” and employees from “Manage Employees” page.")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("View Plant Usage:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("In this screen, you can see how busy a particular plant item is and for which dates it is scheduled.")]),_c('li',[_vm._v("It is a calendar that shows the various jobs the plan item is assigned.")]),_c('br')])
}]

export { render, staticRenderFns }