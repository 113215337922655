<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Getting started with Record Time</h1><br/><br/>
      
      <img :src="`${publicPath}assets/how-to/sign-up-icon.webp`" alt="" style="width:20%" /><br/><br/><br/>
      
     
        <p> Step one-Signup</p>
    
      <ul>
        <li>Visit <a href="https://recordtimeapp.com.au/login">recordtime.com.au</a> and click "Signup" from the menu.</li>
        <li>Sign up for a record time account by entering your email address and your desired password.</li>
      </ul><br/>
      <img :src="`${publicPath}assets/how-to/registration-form.webp`" alt="" style="width:40%" /><br/><br/>
       <img :src="`${publicPath}assets/how-to/gettin-started-with-record-time.webp`" alt="" style="width:30%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/old-way-new-way.webp`" alt="" style="width:100%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/digital-paper-docketing.webp`" alt="" style="width:100%" /><br/><br/>
     
      <img :src="`${publicPath}assets/how-to/choose-plan.webp`" alt="" style="width:20%" /><br/><br/><br/>
     
     <p>Step Two - Choose a plan</p>
      <ul>
        <li><a href="https://recordtimeapp.com.au/login">Click here</a> to login to the backend.</li>
        <li>We offer a 30 day free trial.</li>
        <li>There are no lock-in contracts and you can cancel your subscription at any time.</li>
        <li>You can upgrade or downgrade your subscription at any time. </li>
      </ul><br/>
      <img :src="`${publicPath}assets/how-to/free-trial.webp`" alt="" style="width:30%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/custom-plan.webp`" alt="" style="width:100%" /><br/><br/>
  

      <img :src="`${publicPath}assets/how-to/complete-your-profile.webp`" alt="" style="width:20%" /><br/><br/><br/>
      <p>Step Three - Complete your profile</p>
      <ul>
        <li>Complete your profile with your name and company details. This allow us to contact you to help you set up Record Time for your business.</li>
        <li>The company logo, name, ABN and address you enter on your profile will be used when you create dockets and invoices. You can upload .jpeg, .jpg and .png files for your logo.</li>
      </ul><br/>
      <img :src="`${publicPath}assets/how-to/complete-profile.webp`" alt="" style="width:100%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/complete-your-profile-docketing-system.webp`" alt="" style="width:100%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/profile-record-time.webp`" alt="" style="width:100%" /><br/>

      <img :src="`${publicPath}assets/how-to/step-four.webp`" alt="" style="width:20%" /><br/><br/><br/>
      <p>Step Four - Employee Management</p>
      <ul>
        <li>Go to “Employee Management” from the menu and click “Manage Employees”.</li>
        <li>Add your employees. Your employees will not have access to the backend system unless you make them an admin.</li>
        <li>Once you add your employees, they will receive an email with instructions together with login details to access the app.</li>
    </ul><br/>
      <img :src="`${publicPath}assets/how-to/employee-management.webp`" alt="" style="width:30%" /><br/><br/>
      <img :src="`${publicPath}assets/how-to/all-employee.webp`" alt="" style="width:100%" /><br/><br/>


     <img :src="`${publicPath}assets/how-to/step-five.webp`" alt="" style="width:20%" /><br/><br/><br/>
     <p>Step Five - Docket Book Manager </p>
     <ul>
        <li>Go to “Employee Management” from the menu and click “Manage Employees”.Go to “Docket Book Manager” from the menu and click “Docket Templates”.</li>
        <li>You will find some default templates you can assign to your employees. These can be edited to suit your needs. You can also create your own templates.</li>
        <li>Go to “Docket Book Manager” from the menu and click “Assign Dockets Template” to assign/tag templates for your employees to use</li>
    </ul><br/>
      <img :src="`${publicPath}assets/how-to/docket-book-manager.webp`" alt="" style="width:50%" /><br/><br/>
     







      <div class="yellow-banner">
        Note: How to create a template?
      </div><br/><br/>
      <img :src="`${publicPath}assets/how-to/contactless-docket.webp`" alt="" style="width:100%" /><br/><br/>
          <!-- <li>How does Manage Employees page work?</a></li>
          <li><a @click="$router.push({ name: 'helpDetail', params: { id: 'how-does-manage-employees-page-work'} })">Getting started with Record Time</a></li> -->
  
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
   
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  