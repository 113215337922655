<template>
  <div>
    <br /><br />
    <img
      :src="`${publicPath}assets/blogs/docket-book-app.webp`"
      alt="docket book for: pre start checklist form, property inspection form, chemical hazard form, employee details form"
      style="width:100%"
    />

    <br />
    <br />
    <h1>Ultimate Earthmoving Docket Book for Digital Forms & Dockets Templates</h1>
    <p>Are you tired of drowning in paperwork at your jobsite? Are you buried under heaps of physical forms that take up valuable time and space? Well, it's time to embrace smarter and more efficient solutions – Form Record TIME. Our <a href="https://recordtime.com.au/custom-invoice-book">custom docket book</a> empowers you to create and manage different types of digital forms and dockets, including earthmoving docket book, daily construction timesheet, and delivery dockets, that cater to the unique needs of your jobsite. In addition, Record TIME’s docket book login lets you easily access and update your forms from anywhere. So, say goodbye to the hassle of manual paperwork and say hello to a streamlined, paperless future.</p>
    <br />
    <div class="card-list">
      <h2 class="title">
        Types of Digital Forms You Can Create Using Record TIME’s Earthmoving Docket Book
      </h2>
      <div class="list-container">
        <div>
          <img
            :src="`${publicPath}assets/blogs/pre-start-checklist.webp`"
            alt="pre start checklist form template"
          />
          <h3>Pre Start Checklist Form</h3>
          <p>Are you seeking a dependable solution to guarantee the safety and functionality of your plant and equipment before each use? Whether you're involved in construction, transportation, or any other industry, our earthmoving docket book provides you with different <a href="https://recordtime.com.au/pre-start-checklist">pre start checklist templates</a> to conduct thorough inspections. This way, you can ensure that your operations begin on the right note. The pre start checklist form empowers you to methodically go through essential tasks, thus minimising the risk of oversights. Furthermore, you can tailor the checklist to your specific requirements. You have the option to choose from a variety of predefined items or include your inspection points. To enhance clarity, you can also attach images, diagrams, and reference visuals to each inspection point.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/time-sheet-form.webp`"
            alt="time sheet form template"
          />
          <h3>Time Sheet Form</h3>
          <p>Tracking employee work hours has never been easier. With Record TIME’s earthmoving docket book, employees can conveniently submit time sheet forms along with a timer. Ultimately, this will save time and markedly reduce the likelihood of manual errors. The platform allows users to easily record break times, which are automatically deducted from the total work hours, ensuring accurate compensation calculations. Finally, managers can review and approve the time sheet form, guaranteeing accuracy and compliance before the payroll processing stage. </p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/leave-form.png`"
            alt="leave form template"
          />
          <h3>Leave Form</h3>
          <p>Record TIME’s digital leave form not only simplifies the process for both employees and supervisors but also ensures accurate and efficient leave tracking. Managing employee absences, whether due to vacation, illness, or injury, is undeniably important. However, with Record TIME's Leave Form, this process becomes seamlessly integrated into your workflow. Employees can effortlessly provide essential information, including dates of absence, reasons, and any required documentation. Our docket book enables you to conveniently choose a template and personalise it to your needs. Moreover, you can access submission records on any device, ensuring you stay prepared for unexpected absences. So, say goodbye to surprises and embrace the high quality and streamlined efficiency of tracking employee absences with Record TIME's leave Form.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/chemical-hazard-form.webp`"
            alt="chemical hazard form template"
          />
          <h3>Chemical Hazard Form</h3>
          <p>Every workplace must put a priority on protecting the health and safety of its employees, especially those that contain dangerous chemicals. In this regard, chemical hazard form plays a crucial role in identifying, assessing, and managing chemical-related risks on a jobsite. With Record TIME’s earthmoving docket book, you can collect, organise, and communicate information about the hazardous chemicals present in a jobsite. By offering a concise yet comprehensive overview, the chemical hazard form ensures a clear understanding of the potential risks associated with chemicals on the jobsite. Consequently, this empowers employers, employees, and regulatory bodies to take necessary precautions and actions.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/property-inspection-form.webp`"
            alt="property inspection form template"
          />
          <h3>Property Inspection</h3>
          <p>Whether you are a project manager, site supervisor, or field worker, understanding and utilising a property inspection is crucial for maintaining safety, compliance, and quality standards on your construction or job site. Record TIME's earthmoving docket book provides you with different templates for property inspection, these templates are very easy to modify. Through the utilisation of our property inspection form, you can effectively identify potential hazards, ensure compliance with safety regulations, track progress, and facilitate communication among team members.  In this property inspection form, you can fill out basic details such as the project name, location, inspection date, and the names of the inspector and site manager.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/swms-form.webp`"
            alt="digital safe work method statements form"
          />
          <h3>Safe Work Method Statements Form (SWMS)</h3>
          <p>When it comes to ensuring safety on a construction or industrial jobsite, the implementation of Safe Work Method Statements (SWMS) forms holds significant importance. SWMS is a comprehensive document that outlines the step-by-step procedures for performing high-risk tasks while mitigating potential hazards. It is imperative that you must be prepared before the commencement of high-risk construction work. Record TIME's earthmoving docket book provides different easy to use and modified templates for safe work method statements form. The Safe Work Method Statement Form (SWMS) serves as a structured document that identifies the hazards and risks associated with a particular task or activity. Additionally, it clearly outlines the necessary safety controls, equipment, and procedures to be followed in order to execute the task safely enhancing customer service.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/toolkit-talk-form.webp`"
            alt="toolbox talk form template"
          />
          <h3>Toolbox Talk Form</h3>
          <p>Toolbox Talk Form provides a structured approach to addressing potential hazards and reinforcing best practices among your team. Especially, This form especially focuses on the safety aspects of the day's work, potential hazards, risk mitigation strategies, and any recent incidents that offer valuable lessons. Record TIME's earthmoving docket book provides you with different templates for toolbox talk form, these templates are very easy to modify. Regular safety discussions through Toolbox Talk Form help identify potential risks early on. This proactive approach reduces the likelihood of accidents and injuries. As a result, this empowers workers to make informed decisions and take precautions before starting work. Also, it enables the exchange of knowledge and expertise among team members. Notably, experienced workers can share their insights and lessons learned, contributing to a culture of continuous learning. </p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/employee-details-form.webp`"
            alt="easy-to-use digital employee details form template"
          />
          <h3>Employee Details Form</h3>
          <p>The Employee Details Form acts as a centralised repository for essential information such as personal details, contact information, and more. Record TIME's earthmoving docket book provides you with different templates for employee detail form which are very easy to modify. By utilising this form, companies can reach out to employees effectively during critical announcements. Moreover, maintaining accurate contact details ensures that pertinent information promptly reaches the intended recipients. Record TIME's employee details form is a strategic asset that empowers companies to manage their workforce effectively, ensure legal compliance, and foster a culture of transparency and communication. This digital form is conveniently accessible from various devices, such as smartphones, laptops, tablets, or desktop computers. So, what are you waiting for? Create an effective employee details form using Record TIME’s docket book.</p>
        </div>

        <div>
          <img
            :src="`${publicPath}assets/blogs/induction-form.webp`"
            alt="make your onboarding process easy with record time’s induction form template"
          />
          <h3>Induction Form</h3>
          <p>The induction form is useful to streamline the onboarding process of new employees, contractors, and visitors. It ensures that everyone is well-informed about the necessary safety protocols and regulations before stepping foot on jobsite. Moreover, it serves as an essential tool to communicate vital information, guidelines, and procedures to all individuals present on jobsite. Record TIME's docket book provides you with different templates for induction form, these templates are very easy to modify. With these induction forms, you can learn about the specific safety measures, emergency procedures, and protocols. Also, understand the potential hazards and how to mitigate them effectively.</p>
        </div>
        
      </div>
    </div>

    <div>
      <h2>Benefits of Associating with Record TIME for Earthmoving Docket Book.</h2>
      <p>Record TIME’s digital docket book can be tailored to specific industries and needs. Construction projects, healthcare records, or other context that involves managing and organising documents and information, we got you covered.</p>
      <ul><li><b>Electronic Storage</b></li>
      <p>Record TIME’s docket book allows you to store all relevant forms and dockets in electronic format. This not only conserves physical storage space but also significantly enhances information retrieval speed and efficiency.</p>

      <li><b>Search and Retrieval</b></li>
      <p>With Record TIME, you can easily search for specific documents, keywords, or details within the docket book. </p>

      <li><b>Organization</b></li>
      <p>Record TIME enables you to categorize and organize forms and dockets based on different criteria, such as date, project type, and more. Certainly, this makes it easier to keep track of multiple projects.</p>

      <li><b>Collaboration</b></li>
      <p>Record TIME’s docket book comes with collaboration features. As a result, it permits multiple users to access and work on the same docket simultaneously, making it particularly advantageous for teams operating in remote fields.</p>

      <li><b>Security</b></li>
      <p>Please note that Record TIME includes robust security measures. These encompass user authentication, encryption, and access control, which collectively ensure the utmost security of the docket book’s information.</p>

      <li><b>Notifications and Reminders</b></li>
      <p>Furthermore, you can set up notifications and reminders within Record TIME to keep track of important deadlines, appointments, or tasks associated with each form and docket.</p>

      <li><b>Reporting</b></li>
      <p>Record TIME also provide reporting functionalities, allowing you to generate summaries, statistics, and insights from the data stored in the docket book.</p>

      <li><b>Mobility</b></li>
      <p>Lastly, the convenience factor comes into play as you can access your docket book from various devices. This is especially advantageous for users who are frequently on the move.</p></ul>

      <p>
        Are you ready to revolutionise your jobsite's paperwork management? Join
        countless other businesses who've embraced Record TIME's docket book for a more
        efficient, organised, great customer service and eco-conscious approach to digital forms and dockets.
      </p>
      <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
          @click="changeParam('plant-pre-start-checklist-template')" class="mr-7 btn-1" elevation="0">
          Read More
      </v-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
.card-list {
  background: #f5f5f5;
  padding: 20px 0px;
  margin-bottom: 20px;
  .title {
    text-align: center;
    font-size: 26px !important;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .list-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 2fr));
    grid-template-rows: repeat(2, minmax(150px, 2fr));
    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(150px, 2fr));
      grid-template-rows: repeat(1, minmax(150px, 2fr));
    }
    > div {
      //   flex: 1 1;
      background: white;
      margin: 20px;
      text-align: center;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
      }
      h3 {
        margin-top: 20px;
      }
      p {
        text-align: center;
        padding: 20px;
      }
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    changeParam(param){
      window.location = window.location.origin + "/blog/"+ param
    }
  }
};
</script>
