<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Dashboard Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/backend-logo.webp`" alt="" style="width:100%" /><br/><br/><br/>
  
      <ul>
        <li>You can get to this page by clicking the "Record Time Logo" in the backend as highlighted above:</li>
        <li>This menu gives you a snapshot of how many dockets and invoices you/your company have sent, how many employees you have and if any client requests have been made.</li>
    <li>It also highlights the most recent dockets and invoices that have been sent or received.</li>
  </ul>
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  