<template>
  <div>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'how-does-manage-employees-page-work'">
      <how-does-manage-employees-page-work></how-does-manage-employees-page-work>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'getting-started-with-record-time'">
      <getting-started-with-record-time></getting-started-with-record-time>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'how-to-design-template'">
      <how-to-design-template></how-to-design-template>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'share-your-template'">
      <share-your-template></share-your-template>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'client-management'">
      <client-management></client-management>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'profile-page-work'">
      <profile-page-work></profile-page-work>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'dashboard-menu'">
      <dashboard-menu></dashboard-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'scheduler-menu'">
      <scheduler-menu></scheduler-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'employee-management-menu'">
      <employee-management-menu></employee-management-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'client-menu'">
      <client-menu></client-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'docket-book-manager-menu'">
      <docket-book-manager-menu></docket-book-manager-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'invoice-manager-menu'">
      <invoice-manager-menu></invoice-manager-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'utilities-menu'">
      <utilities-menu></utilities-menu>
    </v-container>
    <v-container class=" text-container" style="display: block;" v-if="url_data == 'profile-menu'">
      <profile-menu></profile-menu>
    </v-container>
    
  </div>
</template>

<style lang="scss">
.text-container {
  height: auto !important;
  .left {
    float: left;
    max-width: calc(100% - 530px);
    padding-right: 15px;
  }
  .right {
    width: 500px;
    float: right;
    img {
      max-width: 600px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    .left {
      float: none;
      max-width: 100%;
      padding-right: 0px;
    }
    .right {
      float: none;
      max-width: 100%;
    }
  }

  .left-blog1 {
    float: left;
    max-width: calc(100% - 800px);
    padding-right: 15px;
  }
  .right-blog1 {
    width: 800px;
    float: right;
    img {
      max-width: 800px;
      width: 100%;
    }
  }

  .left-blog1-new {
    float: left;
    max-width: calc(100% - 600px);
    padding-right: 15px;
  }

  .right-blog1-new {
    width: 600px;
    float: left;
    text-align: center;
    img {
      max-width: 300px;
      width: 100%;
    }
  }

  .right-blog2-new {
    float: left;
    max-width: calc(100% - 600px);
    padding-right: 15px;
  }

  .left-blog2-new {
    width: 600px;
    float: left;
    text-align: center;
    img {
      max-width: 300px;
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    .left-blog1 {
      float: none;
      max-width: 100%;
      padding-right: 0px;
    }
    .right-blog1 {
      float: none;
      max-width: 100%;
    }
  }
}
</style>

<script>
import HowDoesManageEmployeesPageWork from "./HowTo/HowDoesManageEmployeesPageWork.vue";
import GettingStartedWithRecordTime from "./HowTo/GettingStartedWithRecordTime.vue";
import HowToDesignTemplate from "./HowTo/HowToDesignTemplate.vue";
import ShareYourTemplate from "./HowTo/ShareYourTemplate.vue";
import ClientManagement from "./HowTo/ClientManagement.vue";
import ProfilePageWork from "./HowTo/ProfilePageWork.vue";
import DashboardMenu from "./HowTo/DashboardMenu.vue";
import SchedulerMenu from "./HowTo/SchedulerMenu.vue";
import EmployeeManagementMenu from "./HowTo/EmployeeManagementMenu.vue";
import ClientMenu from "./HowTo/ClientMenu.vue";
import DocketBookManagerMenu from "./HowTo/DocketBookManagerMenu.vue";
import InvoiceManagerMenu from "./HowTo/InvoiceManagerMenu.vue";
import UtilitiesMenu from "./HowTo/UtilitiesMenu.vue";
import ProfileMenu from "./HowTo/ProfileMenu.vue";

export default {
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.metaDescription,
        },
        { vmid: "keywords", name: "keywords", content: this.metaKeywords },
      ],
    };
  },
  mounted() {
    this.url_data = this.$route.params.id;

    var noscript = document.getElementsByTagName('noscript');
    noscript[0].remove()
  },
  components: {
    HowDoesManageEmployeesPageWork,
    GettingStartedWithRecordTime,
    HowToDesignTemplate,
    ShareYourTemplate,
    ClientManagement,
    ProfilePageWork,
    DashboardMenu,
    SchedulerMenu,
    EmployeeManagementMenu,
    ClientMenu,
    DocketBookManagerMenu,
    InvoiceManagerMenu,
    UtilitiesMenu,
    ProfileMenu
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      metaTitle: "Record Time",
      metaDescription: "Record Time",
      metaKeywords: "Record Time",
      url_data: "",
    };
  },
  methods: {},
};
</script>
