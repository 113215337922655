<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>How to design a template?</h1><br/>
<p>You can turn any paper form into a digital template with Record TIME. Whether it be a pre-start checklist, WH&S safety checklist, proof of delivery, toolbox talk or a COVID safety checklist, you can do it all from the Record TIME backend. All you need to do is follow the following 4 simple steps, to become a paperless company. In this example, we will design a simple proof of delivery template.</p>
<div class="yellow-banner">
        Note: If you do not have an account with Record TIME, follow this guide: <a href="https://recordtime.com.au/getting-started-wirh-record-time"> Getting started with Record Time</a>
      </div><br/>
     
          <h2>Step 1</h2>
        <br/>
          <ul>
        <li>Login to your <a href="https://recordtimeapp.com.au/">backend</a></li>
        <li>Go to “Docket Book Manager” and click “Docket Templates.</li>
        <li>Click the “Add New” button from the right side of the page.</li>
        <li>On the pop-up window, either select a “Blank template” to create a template from scratch or you can select “Add from Template Bank” and click “Continue” to go next.</li>
      </ul><br/>
      <div class="yellow-banner">
        Note: Template Bank” works based on our registered users' involvement. If you think a template you have created is industry standard and other users can use it, please publish it. Read more...
      </div><br/><br/> 
      <img :src="`${publicPath}assets/how-to/docket-template.webp`" alt="" style="width:100%" /><br/><br/>

      <h2>Step 2</h2><br/>
      <p><b>Add from Template Bank</b></p>
          <ul>
        <li>If you choose to go with the “Add from Template Bank” option, tick the checkbox for it and click “Continue”.</li>
        <li>You will see a list of templates to choose from. You can preview the template and click “Install” once you are happy with it.</li>
        <li>Go to the “Docket Book Manager >> Docket Templates” page once a template has been added/installed. </li>
        <li>Choose the template you have added and open it to make changes to suit your business needs.</li>
        <li>Once you hit “Save” it will ask you to tag/assign your employees. You can choose to assign them later. However, to assign employees in the future, you will have to go to “Docket Book Manager >> Assign Docket Templates” and click “Assign”.
</li>  
    </ul><br/><br/>
    <p><b>Blank Template</b></p>
          <ul>
        <li>If you choose to go with the “Blank Template” option, tick the checkbox for it and click “Continue”.</li>
        <li>On the second window, add the name for your docket template and click next. In this example: we will name the template “Delivery Docket” to make it obvious for our staff.</li>
    
    </ul><br/>
    <img :src="`${publicPath}assets/how-to/docket-info.webp`" alt="" style="width:100%" /><br/><br/>
    <ul>
        <li>Choose the desired method of approval and click "Next"</li><br/>
        
    
    </ul>

    <img :src="`${publicPath}assets/how-to/method-of-approval.webp`" alt="" style="width:100%" /><br/><br/>

    <ul>
        <li>In the last screen click “No” to create a new template. Only click yes if you want your templates to be invoiceable.</li>
        
    
    </ul>



      <div class="yellow-banner">
        Note: You can always update this option from the “Docket Info” section.
      </div><br/>
      

      <h2>Step 3</h2><br/>
      <p>Once your template has been created you can choose to add elements from the “Docket Elements” section. In this example, we have added the following: </p>
          
    <p><b>“Date” element:</b> This is a default field that is created with every template. Employees can use this field to capture the date of delivery.</p>
    <p><b>“Short Text” element:</b> We have added two short text fields and named one “Delivery Driver” and the other “Client”.</p>    
    <ul>
        <li>Employees' name will go under the delivery driver field.</li>
        <li>Employees can record the name of the client in the “Client” field.</li>    
    </ul><br/>
    <p>You can also use “Prefillers”, which are drop-down boxes. This makes filling the docket out faster.”</p>
          
    <p><b>“Location” element:</b> We have named this element “Delivery Location”. This field can auto-populate the current location if location services are turned on in the mobile app. You can also manually enter this detail if required.</p>
    <p><b>“Long Text” element:</b> We have named this element “Description of Items” to list any additional details needed. A long text element provides a multi-line text field for large amounts of text.</p>    
    <p><b>“Image” element:</b> We have added an image element and named it “Image of Items”. This allows employees to take photos of the items being delivered.</p>
    <p><b>“Signature” element:</b> We have named this element “Recipient Signature”. This will help to get a confirmation of delivery from the client. Signatures can be captured effortlessly, by signing on screen. </p>    
    
    <div class="yellow-banner">
        Note: We have also published this template on the “Docket Bank” page to make it easier for anyone who wants to use it for their business.
      </div><br/>
      <p><b>Final Template</b></p>      
      <p>Below is the screenshot of what we have just created:</p>
      <img :src="`${publicPath}assets/how-to/delivery-docket-template-preview.webp`" alt="" style="width:100%" /><br/><br/>
        
      <h2>Step 4</h2><br/>
      <p>Once you hit “Save” it will ask you to tag/assign your employees. You can choose to assign them later. However, to assign employees in the future, you will have to go to “Docket Book Manager >> Assign Docket Templates” and click “Assign”.</p>   
        </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  