<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>How does my profile page work?</h1><br/>
<p>Click your profile image on the top right hand corner in the backend to access your profile page/settings. The information below highlights how each of the menu items work:</p>

<img :src="`${publicPath}assets/how-to/record-time-admin-board.webp`" alt="" style="width:100%" />  
    
<br/><br/><h2>My Subscription</h2><br/>

<p>Outlines your current subscription details such as the subscription date, next payment/end of trial date, plan details and account status. You can also upgrade your plan by clicking the “Upgrade” button.</p>

<br/><h2>My Profile</h2><br/>

<p>My profile lets you update your company details including your logo, ABN number, business address, contact number and your full name. The company details you enter on your profile will be used when you create your dockets and invoices. You can upload .jpeg, .jpg and .png files for your logo. </p>

<br/><h2>Invoice Settings</h2><br/>

<p>You can attach your banking information with instructions. This information will be displayed on your invoice.</p>

<p>It will be displayed like this:</p>
<img :src="`${publicPath}assets/how-to/Invoice-settings.webp`" alt="" style="width:100%" />  
<br/><h2>Docket Settings</h2><br/>

<p>You can create, edit and delete “Terms & Conditions” from this section. Once created, these can be loaded onto any template using the ‘Terms and Conditions’ element on the docket template.</p>

<br/><h2>Change Password</h2><br/>

<p>Update your password at any time through this menu. If you have forgotten your existing password, head to the login screen and click “Forgot Your Password?”. You will then receive a “Password Reset Link” to change your password.</p> 

<br/><h2>Update Credit Card</h2><br/>

<p>Update/remove your credit card details at any time. Please note: credit card details are not stored on our servers.</p>

<br/><h2>Billing History</h2><br/>

<p>You can track your billing history including an ability to view/download your invoices. </p>

<br/><h2>Xero Setting</h2><br/>

<p>If you have a Xero account with payroll access, you can connect with Record TIME to sync Invoices and Xero timesheet enabled templates.</p>
        </div>
  </template>
  <style scoped lang="scss">
   
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  