var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"40px"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'help' })}}},[_vm._v("◀ How To's")]),_c('h1',[_vm._v("Invoice Manager Menu")]),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/invoice-manager-record-time.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(0),_c('p',[_vm._v("Invoice page works similar to dockets page. From this page you are able to view all Sent, Received and Emailed invoices.")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('br'),_vm._m(4),_vm._m(5),_c('div',{staticClass:"yellow-banner"},[_vm._v(" Note: You will have to tick the “Can Invoice” checkbox "),_c('img',{staticStyle:{"width":"10%"},attrs:{"src":`${_vm.publicPath}assets/how-to/can-invoice.webp`,"alt":""}}),_vm._v(" on their profile to make the option to send dockets available on their mobile app. ")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/invoice-setting-record-time.webp`,"alt":""}}),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/invoice-banking-record-time.webp`,"alt":""}}),_c('br'),_c('br'),_c('center',[_c('p',[_vm._v("Banking on Invoice")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Invoices")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("From this section you can export csv/excel files or pdf’s.")]),_c('li',[_vm._v("Move invoices into folders ")]),_c('li',[_vm._v("You can create folders to make it project specific and set it up in a hierarchy similar to your folders on the computer.")]),_c('li',[_vm._v("You are also able to send invoices directly from the backend, filter invoices, view them and much more. ")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Invoices Templates:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Similar to dockets templates page, from this page you are able to create new invoice templates, edit existing templates, delete and assign templates to a folder.")]),_c('li',[_vm._v("Assigning invoice to a specific folder will automatically be placed into the selected folder for easier file keeping.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Assign Invoice Templates:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page lets you assign invoice Templates to Employees through this menu or you can assign them directly before publishing your templates.")]),_c('li',[_vm._v("You can nominate who can send invoices to your clients directly.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Invoice Label")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Invoice label concept works the same as docket label. However, the labels created on this page are only accessible on invoices.")]),_c('li',[_vm._v("Invoice label page lets you create Invoice Labels to mark your invoices. Invoice labels are like tags or post-it notes in the real world, they help you mark off or highlight an invoice to keep track of its status.")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Invoice Setting")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("If this section is completed, your banking information is automatically listed on the bottom of your invoices.")]),_c('li',[_vm._v("You can also amend the “instruction” or “additional information” text box as required. This will ensure your customers know where to pay your invoice and your company’s terms for payment.")]),_c('li',[_vm._v("Once your banking information is entered, please click the save button to save the information.")]),_c('li',[_vm._v("Any invoices sent from this point onwards to your clients will automatically display your banking details and additional information. ")]),_c('br')])
}]

export { render, staticRenderFns }