<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>How does the Client Management page work?</h1><br/>
<p>The client page has two tabs/pages and when you first signup, it will look similar to this:</p>
<img :src="`${publicPath}assets/how-to/Client-management-page.webp`" alt="" style="width:100%" />  



<h2>Clients</h2><br/>
<p>Clients tab/page lets you find a client from the list of already signed up and happy clients to send dockets straight to their mobile apps. This allows for a quick in app notifications and dockets approvals.</p>
<p>In order to connect with your clients, you will have to find them in the list by clicking “Find Client” as highlighted on the above picture. A pop-up search bar will appear and you will have to enter the name of your clients company and click “Add Client” to send a request to connect. </p>
<img :src="`${publicPath}assets/how-to/Client-search-record-time.webp`" alt="" style="width:100%" />  
   
<p>Your client will receive a notification on their backend system, once they have accepted your request, you will be able to send each other dockets on the mobile apps.</p><br/>
<img :src="`${publicPath}assets/how-to/notification-on-backend.webp`" alt="" style="width:40%" /> <br/>   <br/>
<img :src="`${publicPath}assets/how-to/client-request.webp`" alt="" style="width:100%" /> <br/>   <br/>
<h2>Custom Clients </h2><br/>
<p>If the clients you are working with are not already using Record Time, you have the option to add their email address and company details on the “Custom Clients” tab/page. The details you enter for your clients such as their full name, email, company name and address will be used to send dockets and invoices via email.</p>
<img :src="`${publicPath}assets/how-to/Custom-clients-page.webp`" alt="" style="width:100%" />   


</div>
  </template>
  <style scoped lang="scss">
   
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  