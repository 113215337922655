var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"40px"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'help' })}}},[_vm._v("◀ How To's")]),_c('h1',[_vm._v("Profile Menu")]),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/profile-page.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/setting-invoice.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/banking-invoice.webp`,"alt":""}}),_c('br'),_c('br'),_vm._m(6),_vm._m(7),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/docket-setting-tnc.webp`,"alt":""}}),_c('br'),_c('br'),_c('br'),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":`${_vm.publicPath}assets/how-to/adding-tnc-from-docket-template.webp`,"alt":""}}),_c('br'),_vm._m(8),_vm._m(9),_c('br'),_vm._m(10),_vm._m(11),_c('br'),_vm._m(12),_vm._m(13),_c('br'),_vm._m(14),_vm._m(15),_c('br'),_vm._m(16),_vm._m(17)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Mr Subscription:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page allows you to view your existing plan information and lets you upgrade or downgrade your plan. ")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Profile:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("To come to this section, you can click your name from the top right corner of the page.")]),_c('li',[_vm._v("Once you click your name, you come to your profile page where you can update your company and contact details.")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Invoice Setting:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("If this section is completed, your banking information is automatically listed on the bottom of your invoices.")]),_c('li',[_vm._v("You can also amend the “instruction” or “additional information” text box as required. This will ensure your customers know where to pay your invoice and your company’s terms for payment.")]),_c('li',[_vm._v("Once your banking information is entered, please click the save button to save the information.")]),_c('li',[_vm._v("Any invoices sent from this point onwards to your clients will automatically display your banking details and additional information.")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Docket Settings:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("You can create terms and conditions template from this page.")]),_c('li',[_vm._v("Simply click the “Add New” button and a pop up window will appear. You just have to add a title and your terms and conditions to get started.")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Change Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page allows you to change/update your password or you can update it from the employee management page. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Update Credit Card:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page lets you add, update or delete your credit card details.")]),_c('li',[_vm._v("Please update your credit card details to avoid interruptions after your trial ends.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Billing History")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page shows all the invoices you have paid for the use of Record Time software.")]),_c('li',[_vm._v("You can view the date of payment, total amount paid and even download invoices from this section. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Xero Setting:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("This page lets you link your Xero software with Record Time to enable syncing invoices and timesheet. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('u',[_vm._v("Date Settings:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("By default, the users are able to select any date range.")]),_c('li',[_vm._v("With the restricted dates, you can control how far back or in future they are able to pick the dates. ")])])
}]

export { render, staticRenderFns }