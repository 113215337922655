import { render, staticRenderFns } from "./InvoiceManagerMenu.vue?vue&type=template&id=6146f675&scoped=true"
import script from "./InvoiceManagerMenu.vue?vue&type=script&lang=js"
export * from "./InvoiceManagerMenu.vue?vue&type=script&lang=js"
import style0 from "./InvoiceManagerMenu.vue?vue&type=style&index=0&id=6146f675&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6146f675",
  null
  
)

export default component.exports