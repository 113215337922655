<template>
  <div>
    <landing-banner>
      <template v-slot:title>
       An Innovative <span>Business Management Software</span>
      </template>

      <template v-slot:subTitle>
        
        Record TIME  was initially used as management software for the construction industry to digitalise docketing and remote approvals. However, as time goes on, Record TIME expands its potential features and unfolds into an extensive business management software that also serves different industries.
        
      </template>

      <template v-slot:image>
        <img :src="`${publicPath}assets/About/construction-company-software.webp`" alt="construction project management software">
      </template>

      <template v-slot:main>
        <secondary-btn-row @firstBtnClicked="firstBtnClicked" @secondBtnClicked="secondBtnClicked"/>

        <p class="customers-container-prefix"> 
          <b>Maximizing Efficiency with a Business Management System</b>
        </p>
          <div class="customers-container" :style="{ 'width': landingBannerContainerWidth + 'px' }">
          <img :src="`${publicPath}assets/About/construction-management-software.webp`" alt="Best scheduling software for construction" style="height:15rem;object-fit: contain;">
          <img :src="`${publicPath}assets/About/construction-risk-assessment.webp`" alt="Construction risk assessment with business management software" style="height:15rem;object-fit: contain;">
          <img :src="`${publicPath}assets/About/remote-approvals.webp`" alt="Construction company using digital signature online for remote approvals" style="height:15rem;object-fit: contain;">
          <img :src="`${publicPath}assets/About/construction-document.webp`" alt="Man after getting certificate of completion construction" style="height:15rem;object-fit: contain;">
        </div>
        <p :style="{ 'width': landingBannerContainerWidth + 'px', 'padding-bottom': '0px' }">Record TIME is designed to optimise every area of your business operations, from employee time tracking and project management to invoicing and digital docketing. With this business management, not only can you have full control over your business process, but you can also increase productivity and achieve outstanding results.</p>
      </template>
    </landing-banner>

    <div class="features-about-section">
      <v-container class="features-container">
        <p>WHY RECORD TIME?</p>
        <h2>Considered the Best Business Management Software </h2>
        <p>
          At Record Time, we understand the unique requirements of different industries, and our system is flexible and adaptive to meet your specific requirements. No matter how big or small your company is, our system develops with you. Moreover, it offers a solid and dependable solution as your company expands. Furthermore, our business management software allows you to manage multiple projects from a single dashboard. It gives real time updates and instant communication and you can remain on top of every aspect of your project with this management system. As a result, you can gain a competitive advantage as this ensures seamless execution and timely completion.

        </p>

        <div class="checklist-row">
          <div class="checklist-col">
            <v-avatar size="24" color="primary">
              <v-icon size="12" color="white">{{ mdiCheck }}</v-icon>
            </v-avatar>
            <p>Flexible & Adaptable</p>
          </div>
          <div class="checklist-col">
            <v-avatar size="24" color="primary">
              <v-icon size="12" color="white">{{ mdiCheck }}</v-icon>
            </v-avatar>
            <p>Easy to Use</p>
          </div>
          <div class="checklist-col">
            <v-avatar size="24" color="primary">
              <v-icon size="12" color="white">{{ mdiCheck }}</v-icon>
            </v-avatar>
            <p>Industry Proven</p>
          </div>
        </div>

        <h2>THE RECORD TIME JOURNEY</h2>
        <div class="features-row">
          <img :src="`${publicPath}assets/About/birth-of-record-time.webp`" alt="Record TIME business management software for construction company">
          <div class="text-section">
            <p>1. Birth of Record TIME</p>
            <h3>2014</h3>
            <p>
              The first version of Record TIME was released in 2014. Initially, it served as a prototype that we used to understand the needs and wants of our customers. We spoke to over 100+ Companies from different industries such as plumbing, concreting, earthworks, and construction to test our prototype.
            </p>
          </div>
        </div>

        <div class="features-row">
          <div class="text-section">
            <p>2. Record TIME Re-Launched</p>
            <h3>2018</h3>
            <p>
              Record TIME re-launches with a key feature: The Modular Form / Docket capability. This key innovation allowed us to replicate any form into its digital equivalent. Further, armed with our market research, we released highly demanded features such as the Bundy clock and job scheduling system.
            </p>
          </div>
          <img :src="`${publicPath}assets/About/record-time-relaunched.webp`" alt="Business management software for construction company">
        </div>

        <div class="features-row">
          <img :src="`${publicPath}assets/About/job-management-record-time.webp`" alt="Record time docketing and job management system">
          <div class="text-section">
            <p>3. Record TIME Today</p>
            <h3>App used by thousands</h3>
            <p>
              Record TIME has now evolved into a fully-fledged digital docketing and project management system. Thousands of users in various industries use it every day. As well as it is constantly changing and the features that we build/release are features that customers want.
            </p>
          </div>
        </div>  
      </v-container>
      <v-container>
        <h3>Powerful Additional Features</h3>
        <p>
          With an extensive range of additional features, including <a href="https://recordtime.com.au/digital-docketing">digital docketing</a>, <a href="https://recordtime.com.au/pre-start-checklist">pre start checklist</a>, <a href="https://recordtime.com.au/job-scheduling-software">job scheduling</a>, <a href="https://recordtime.com.au/field-staff-real-time-tracking">field staff real time tracking</a>, <a href="https://recordtime.com.au/custom-invoice-book">custom invoice book</a>, <a href="https://recordtime.com.au/jobsite-management-software">jobsite management</a>, <a href="https://recordtime.com.au/proof-of-work-completion">proof of work completion</a>, and <a href="https://recordtime.com.au/remote-approval-of-site-work">remote approval</a>, Record TIME has now emerged as the preferred management software option for business processes across various sectors.
          <br><br><a href="https://recordtime.com.au/blog/business-management-solution">Know More</a>
        </p>
        
      </v-container>
    </div>
    <video-modal v-if="showModal" @showModal="transitionend" url="https://www.youtube.com/embed/62kV7CIU_F4"/>
  </div>
</template>

<style lang="scss">
.customers-container-prefix {
  font-size: 14px;
  line-height: 1.86;
  color: $faux-roman-silver;
}

.customers-container {
  position: relative;
  display: flex;
  transform: translateY(-40px);

  img {
    height: 144px;
    min-width: 10px;
    flex-grow: 1;

    @media only screen and (max-width: 600px) {
      object-fit: cover;
      object-position: top;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.features-about-section {
  background-color: $alice-grey;
  margin-top: 110px;

  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }

  .features-container {
    padding-top: 40px;

    & > p {
      &:nth-of-type(1) {
        opacity: 0.3;
        letter-spacing: 4px;
        text-align: center;
        color: $black-pearl;
        margin: 0;
        padding-top: 0;
        text-transform: uppercase;
        padding-bottom: 10px;
        
      }

      &:nth-of-type(2) {
        font-size: 18px;
        line-height: 1.44;
        text-align: center;
        color: $faux-roman-silver;
        margin-top: 16px;

        span {
          color: $black-pearl;
        }

        @media only screen and (max-width: 600px) {
          font-size: 14px;
          line-height: 1.86;
        }
      }
    }

    & > h2 {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: #021222;
    }

    .checklist-row {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 24px;
      border-bottom: 1px solid $faux-sw-paint;
      margin-bottom: 50px;
      @media only screen and (max-width: 600px) {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
        flex-direction: column;
      }

      .checklist-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin: 0 44px;
        @media only screen and (max-width: 600px) {
          width: unset;
          margin: 0 0 34px 0;
        }

        p {
          font-size: 18px;
          font-weight: 600;
          line-height: 1.56;
          text-align: center;
          color: $black-pearl;
          margin-top: 8px;
        }
      }
    }

    .features-row {
      display: flex;
      justify-content: center;
      align-items: stretch;
      height: 308px;
      margin-bottom: 80px;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
        height: unset;
        display: block;
        margin-bottom: 45px;

        img {
          height: 190px;
          width: 100%;
          padding-top: 34px;
        }

        &:nth-of-type(1) {
          img {
            padding-left: 20px;
          }
        }

        &:nth-of-type(2) {
          margin-bottom: 0;

          img {
            padding-right: 20px;
          }
        }

        &:nth-of-type(3) {
          img {
            padding-left: 20px;
          }
        }

        .text-section {
          margin-top: 34px;
          align-items: center;
          max-width: unset;

          p {
            text-align: justify;

            &:nth-of-type(2) {
              padding-bottom: 24px !important;
            }
          }

          h3 {
            padding-bottom: 16px;
          }
        }
      }

      img {
        height: 100%;
      }

      .text-section {
        max-width: 370px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media only screen and (max-width: 600px) {
          max-width: unset;
        }

        * {
          margin: 0;
          padding: 0;
        }

        p {
          &:nth-of-type(1) {
            font-size: 14px;
            letter-spacing: 1px;
            color: $faux-dark-turquoise;
            padding: 6px;
          }

          &:nth-of-type(2) {
            opacity: 0.5;
            font-size: 14px;
            line-height: 1.57;
            color: $black-pearl;
            padding-bottom: 30px;
          }
        }

        h3 {
          font-size: 24px;
          font-weight: bold;
          color: $black-pearl;
          padding-bottom: 30px;
        }

        a {
          font-size: 16px;
          line-height: 1.5;
          color: $black-pearl;
          padding-bottom: 3px;
          border-bottom: 1px solid $black-pearl;
          width: fit-content;

          &:hover, &:focus {
            color: $faux-dark-turquoise;
            border-bottom: 1px solid $faux-dark-turquoise;
          }
        }
      }
    }

  }
}
</style>

<script>
// @ is an alias to /src
import LandingBanner from '@/components/LandingBanner.vue';
import SecondaryBtnRow from '@/components/SecondaryBtnRow1.vue';
import {mdiCheck} from '@mdi/js';
import bannerImage from '@/assets/About/banner.png';
import VideoModal from '@/components/VideoModal.vue';

export default {
  metaInfo: {
    title: 'Business Management Software for all Industries | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Business management software developed to help businesses of all sizes to organise and automate their business operations.'},
      {name: 'keyword', content: 'business management software'},
      
    ],
    link: [
      {rel: 'canonical', href: 'https://recordtime.com.au/about' }
    ]
  },
  components: {
    LandingBanner, SecondaryBtnRow, VideoModal
  },
  data() {
    return {
      testimonialWindow: 0,
      mdiCheck: mdiCheck,
      landingBannerContainerWidth: 0,
      bannerImage: bannerImage,
      showModal: false,
      publicPath: process.env.BASE_URL
    }
  },
  mounted() {
    if (document.getElementsByClassName("landing-banner-container").length > 0) {
      this.landingBannerContainerWidth =
          document.getElementsByClassName("landing-banner-container")[0].clientWidth;
    }
  },
  computed: {
    computedTestimonials() {
      let result = [];
      if (this.$vuetify.breakpoint.lgAndUp) {
        result = this.chunkArray(this.testimonials, 3);
      } else if (this.$vuetify.breakpoint.mdOnly) {
        result = this.chunkArray(this.testimonials, 3);
      } else if (this.$vuetify.breakpoint.smOnly) {
        result = this.chunkArray(this.testimonials, 2);
      } else if (this.$vuetify.breakpoint.xsOnly) {
        result = this.chunkArray(this.testimonials, 1);
      }
      return result;
    },
    
  },
  methods: {
    firstBtnClicked() {
      window.open('https://recordtimeapp.com.au/register', '_blank');
    },
    secondBtnClicked() {
      this.showModal = true;
    },
    transitionend() {
      this.showModal = false;
    }
  }
}
</script>
