<template>
    <div style="margin-top: 40px;">
      <a @click="$router.push({ name: 'help' })">&#9664; How To's</a>
      <h1>Docket Book Manager Menu</h1><br/><br/>
      <img :src="`${publicPath}assets/how-to/docket-book-manager-menu.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Dockets</u></p>
      <ul>
        
        <li>From this page you are able to view all Sent, Received and Emailed Dockets.</li>
    <li>From this section you can export csv/excel files or pdf’s.</li>
    <li>Move dockets into folders. </li>
    <li>You can create folders to make it project specific and set it up in a hierarchy similar to your folders on the computer.</li>
    <li>You can filter, view, approve, download and reject dockets and much more.</li><br/>
  
    </ul>
    <p><u>Docket Templates:</u></p>
      <ul>
        
        <li>You can create new Docket Templates, you can choose to either start templates from scratch or select a template from the <b>docket bank</b>.</li>
    <li>From this page you are able to edit existing templates, clone, publish and Delete Templates using the red button beside each template.</li>
    <li>You can also assign Templates to specific folders so when a docket is created using that template, it will automatically be placed into the selected folder for easier file keeping.</li><br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/docket-templates-pages.webp`" alt="" style="width:100%" /><br/><br/><br/>
      <p><u>Assign Docket Templates</u></p>
      <ul>
        
        <li>This page lets you assign Docket Templates to Employees or you can assign them directly before publishing your templates.</li>
    <li>You can assign one docket template to multiple employees at the same time or you can assign them individually. <b>Please</b> ensure you have added your employees before assigning templates.</li>
    <li>This page also highlights which employees are assigned to which templates and you are able to remove/unlink the templates from their profile by clicking on the delete button.</li>
    <li>The templates you(admin) assigns will be displayed on your employees mobile devices automatically on record time app.</li>
    <br/>
  
    </ul>


    <div class="yellow-banner">
      Note: You will have to tick the “Can Docket” checkbox <img :src="`${publicPath}assets/how-to/docket.webp`" alt="" style="width:10%" /> on their profile to make the option to send dockets available on their mobile app.
    </div><br/><br/>
    <img :src="`${publicPath}assets/how-to/assign-docket-template.webp`" alt="" style="width:100%" /><br/><br/>
    <img :src="`${publicPath}assets/how-to/assign-dockets.webp`" alt="" style="width:100%" /><br/><br/>

      
    <p><u>Docket Label:</u></p>
      <ul>
        
        <li>Docket label page lets you create Labels to mark your dockets via the ‘Dockets’ page.</li>
    <li>Docket labels are like tags or post-it notes. They help you mark off or highlight a docket to keep track of its status.</li>
    <li>You can colour code it, use an icon or name it as required. For example: “Processed”, “Invoiced”, “Entered into MYOB or XERO”.</li>
    <li>You are able to create new Docket Labels as well as edit or Delete existing labels from this page.</li>
    <br/>
  
    </ul>

    <img :src="`${publicPath}assets/how-to/docket-labels.webp`" alt="" style="width:100%" /><br/><br/>      
    <img :src="`${publicPath}assets/how-to/using-docket-label.webp`" alt="" style="width:100%" /><br/><br/>         
        
    <p><u>Docket Settings:</u></p>
      <ul>
        
        <li>You can create universal Terms & Conditions/disclaimer for your templates from this page.</li>
    <li>You can create different disclaimers for different templates as required and load it up on your docket template by adding “Terms & Conditions” element and selecting the desired template.</li>
    <li>You can add new terms and conditions as well as edit and delete existing terms and it will update automatically on all linked templates.</li>

    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/docket-setting.webp`" alt="" style="width:100%" /><br/><br/>  
    <img :src="`${publicPath}assets/how-to/adding-tnc.webp`" alt="" style="width:80%" /><br/><br/>  
    <p><u>Project:</u></p>
      <ul>
        
        <li>Create Projects to keep track of your budget and manage project spending.</li>
    <li>Enter your Budget value and select the Templates you wish to count towards the tally.</li>
 

    <br/>
  
    </ul>
    <div class="yellow-banner">
        Note: You must create the project first for dockets to be included - dockets created with the project templates beforehand will not be included in the tally.
    </div><br/><br/>
    <img :src="`${publicPath}assets/how-to/project-page.webp`" alt="" style="width:100%" /><br/><br/>  
    <p><u>Docket Bank:</u></p>
      <ul>
        
        <li>This page list Templates that have been created and uploaded by Record TIME as well as other Users/companies.</li>
    <li>You are able to search and preview the templates before installing it in your backend. Either use them exactly as they are or modify them to suit your specific needs.</li>
    <li>Once installed, you will have to go to docket templates to edit it and assign dockets page to assign them.</li>
 

    <br/>
  
    </ul>
    <img :src="`${publicPath}assets/how-to/docket-bank-preview.webp`" alt="" style="width:100%" /><br/><br/>  
  </div>
  </template>
  <style scoped lang="scss">
    .yellow-banner{
      background-color: #ffe652;
      padding: 10px;
      border-color: #deca4f;
      color: #615959;
      border-left-width: 5px;
      border-left-style: solid;
      margin: 15px 15px 15px 0px;
    }
  </style>
  <script>
  export default {
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    methods: {
      changeParam(param){
        window.location = window.location.origin + "/"+ param
      }
    }
  };
  </script>
  